
import { defineComponent } from "vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import gtmAuthPlace from "@/mixins/gtmAuthPlace";

export default defineComponent({
  name: "Header",
  components: { HeaderMenu },
  props: {
    topMenuItems: {
      type: Array,
      default: []
    },
    subMenuItems: {
      type: Array,
      default: []
    }
  },
  mixins: [gtmAuthPlace],
  data() {
    return {
      dropdownProductsItems: [
        {
          classes: "products-item--hr",
          color: "#F6E4FC",
          desc: "Integrate and Appreciate Your Team",
          image: "products-hr.svg",
          link: "/products/hr",
          title: "Gifted HR"
        },
        {
          classes: "products-item--swag",
          color: "#DDF4F3",
          desc: "Recognition You Can Hold.",
          image: "products-swag.svg",
          link: "/products/swag",
          title: "Gifted Swag"
        },
        {
          classes: "products-item--client",
          color: "#FCE4E5",
          desc: "Put Your Brand in the Right Hands",
          image: "products-clients.svg",
          link: "/products/client",
          title: "Gifted Clients"
        }
      ],
      authenticated: false,
      isDropdownProductsOpen: false,
      isHeaderMenuOpen: false,
      isHeaderSticky: false,
      hoverableItemIndex: null,
      headerMainHeight: 0,
      lastScrollTop: 0,
      headerStart: true,
      isHoverableArea: false,
      isSecondaryNavDesktopInit: false,
      itemsMoveIndex: 0,
      isSubmenuMoreItem: false,
      logoOuterWidth: 0,
      navSecondaryItemsWidth: 0,
      navSecondaryItemsWidthList: [] as number[],
      headerProductContainerWidth: 0,
      containerPaddings: 80,
      moreItemWidth: 250,
      navSecondaryItemMargin: 48,
      checkNavWidthTimeOut: 0
    };
  },
  computed: {
    mainNavLinks(): any[] {
      const menu = this.topMenuItems;

      if (menu && menu.length) {
        menu.forEach(navItem => {
          // @ts-ignore
          navItem.isOpen = false;
        });
      }

      return menu;
    },
    pageRelatedLinks(): any[] {
      const menu = this.subMenuItems;

      if (menu && menu.length) {
        menu.forEach(navItem => {
          // @ts-ignore
          navItem.isOpen = false;
        });
      }

      return menu;
    },
    productMenuLogo(): string {
      let name = "";

      if (this.$route.params.slug === "hr") {
        name = "Gifted <span class='highlighted'>HR</span>";
      }

      if (this.$route.params.slug === "swag") {
        name = "Gifted <span class='highlighted'>Swag</span>";
      }

      if (this.$route.params.slug === "client") {
        name = "Gifted <span class='highlighted'>Client</span>";
      }

      return name;
    }
  },
  methods: {
    onDropdownProductsItemClick(index: number) {
      this.$router.push(this.dropdownProductsItems[index].link);
      this.isDropdownProductsOpen = false;
      this.isHeaderMenuOpen = false;
    },
    onDropdownProductsMouseOver() {
      if (
        // @ts-ignore
        this.responsiveState !== "mobile"
      ) {
        this.isHoverableArea = true;
        this.isDropdownProductsOpen = true;
      }
    },
    onDropdownProductsMouseLeave(ev: any) {
      if (
        ev.toElement !== this.$refs.productsDropdownMenu &&
        ev.toElement !== this.$refs.productsMenuItem &&
        ev.toElement !== this.$refs.productsMenuItemLink
      ) {
        setTimeout(() => {
          this.isHoverableArea = false;
          this.isDropdownProductsOpen = false;
        }, 150);
      }
    },
    onScroll() {
      this.isHeaderSticky = window.scrollY > 0;
      this.headerStart = this.lastScrollTop === 0;

      if (
        // @ts-ignore
        this.responsiveState !== "mobile" &&
        this.pageRelatedLinks.length &&
        !this.isDropdownProductsOpen
      ) {
        if (window.scrollY > this.headerMainHeight) {
          // @ts-ignore
          this.$refs.header.style.position = "fixed";
          this.headerStart = false;

          setTimeout(() => {
            // @ts-ignore
            this.$refs.headerMain.style.transition = "all 0.13s ease-in-out";
          }, 300);

          if (window.scrollY > this.lastScrollTop) {
            // @ts-ignore
            this.$refs.headerMain.style.marginTop = `-${this.headerMainHeight}px`;
          } else {
            // @ts-ignore
            this.$refs.headerMain.style.marginTop = "";
          }
        } else if (this.headerStart) {
          // @ts-ignore
          this.$refs.header.style.position = "absolute";
          // @ts-ignore
          this.$refs.headerMain.style.transition = "";
        }
      }

      this.lastScrollTop = window.scrollY;
    },
    onResize() {
      clearTimeout(this.checkNavWidthTimeOut);

      this.checkNavWidthTimeOut = setTimeout(() => {
        this.checkSecondaryNav();
      }, 250);

      // @ts-ignore
      if (this.responsiveState === "mobile") {
        // @ts-ignore
        this.$refs.header.style.position = "";
        // @ts-ignore
        this.$refs.headerMain.style.transition = "";
        // @ts-ignore
        this.$refs.headerMain.style.marginTop = "";
      } else {
        // @ts-ignore
        this.headerMainHeight = this.$refs.headerMain.clientHeight;
      }
    },
    onMenuButtonClick() {
      document.body.classList.toggle("no-scroll");
      this.isHeaderMenuOpen = !this.isHeaderMenuOpen;
    },
    onHeaderLogoClick() {
      this.$router.push("/");

      if (this.isHeaderMenuOpen) {
        document.body.classList.remove("no-scroll");
        this.isHeaderMenuOpen = false;
      }
    },
    checkSecondaryNav() {
      // @ts-ignore
      if (this.pageRelatedLinks.length && this.responsiveState !== "mobile") {
        if (!this.isSecondaryNavDesktopInit) {
          // @ts-ignore
          this.navSecondaryItemsWidth = this.$refs.navListSecondary.offsetWidth;

          if (this.productMenuLogo) {
            // @ts-ignore
            this.logoOuterWidth = this.$refs.logoOuterSecondary.offsetWidth;
          }

          for (let i = 0; i < this.pageRelatedLinks.length; i++) {
            const currentRef = "relatedLink" + i;
            // @ts-ignore
            let navSecondaryItemWidth = this.$refs[currentRef].offsetWidth;
            if (i !== 0) navSecondaryItemWidth += this.navSecondaryItemMargin;
            this.navSecondaryItemsWidthList.push(navSecondaryItemWidth);
          }

          this.isSecondaryNavDesktopInit = true;
        }

        this.checkSecondaryNavWidth();
      }
    },
    checkSecondaryNavWidth() {
      // @ts-ignore
      // prettier-ignore
      this.headerProductContainerWidth = this.$refs.headerProductContainer.offsetWidth - this.containerPaddings;
      this.isSubmenuMoreItem = this.itemsMoveIndex > 0;
      let itemMoreWidth = 0;
      let itemsToMoreWidth = 0;
      // prettier-ignore
      const lastItemMoveIndexWidth = this.navSecondaryItemsWidthList[this.pageRelatedLinks.length - this.itemsMoveIndex];

      if (this.isSubmenuMoreItem) {
        // prettier-ignore
        for (let i = this.pageRelatedLinks.length - this.itemsMoveIndex; i < this.pageRelatedLinks.length; i++) {
          itemsToMoreWidth += this.navSecondaryItemsWidthList[i];
        }

        itemMoreWidth = this.moreItemWidth;
      }

      // prettier-ignore
      const availableWidth = this.headerProductContainerWidth - this.logoOuterWidth - itemMoreWidth;

      if (this.navSecondaryItemsWidth - itemsToMoreWidth > availableWidth) {
        // prettier-ignore
        if (this.itemsMoveIndex !== this.pageRelatedLinks.length - 1) this.itemsMoveIndex++;
        this.checkSecondaryNavWidth();
      } else if (this.navSecondaryItemsWidth - itemsToMoreWidth + lastItemMoveIndexWidth <= availableWidth) {
        // prettier-ignore
        if (this.itemsMoveIndex > 0) this.itemsMoveIndex--;
        this.checkSecondaryNavWidth();
      } else {
        this.isSubmenuMoreItem = this.itemsMoveIndex > 0;
      }
    },
    clickSignUp() {
      this.gtmTrackAndSave('signup', 'Top right');
      //@ts-ignore
      window.location.href = `${this.businessPrefix}login#/sign-up`;
    },
    clickLogIn() {
      this.gtmTrackAndSave('login', 'Top right');
      //@ts-ignore
      window.location.href = `${this.businessPrefix}login`;
    },
  },
  mounted() {
    // @ts-ignore
    this.headerMainHeight = this.$refs.headerMain.clientHeight;
    this.authenticated = Boolean(localStorage.getItem('auth-data'))
    setInterval(() => {
      this.authenticated = Boolean(localStorage.getItem('auth-data'))
    }, 1500)

    this.checkSecondaryNav();

    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
  }
});
