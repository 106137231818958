import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "header-menu" }
const _hoisted_2 = { class: "header-menu__body" }
const _hoisted_3 = { class: "header-nav header-nav--mobile" }
const _hoisted_4 = { class: "header-nav__list" }
const _hoisted_5 = { class: "products-item__text" }
const _hoisted_6 = { class: "products-item__title" }
const _hoisted_7 = { class: "products-item__desc" }
const _hoisted_8 = {
  key: 0,
  class: "header-nav__list"
}
const _hoisted_9 = {
  key: 0,
  class: "header-nav__list"
}
const _hoisted_10 = { class: "header-menu__footer" }
const _hoisted_11 = { class: "header-menu__buttons d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("nav", _hoisted_3, [
        _createVNode("ul", _hoisted_4, [
          _createVNode("li", {
            class: [
              'header-nav__list-item',
              'header-nav__list-item--dropdown',
              'header-nav__list-item--products',
              _ctx.isProductsOpen && 'header-nav__list-item--active'
            ]
          }, [
            _createVNode("span", {
              class: "header-nav__link",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isProductsOpen = !_ctx.isProductsOpen))
            }, _toDisplayString(_ctx.$t("text.products")), 1),
            (_ctx.isProductsOpen)
              ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(_ctx.dropdownProductsItems, (item, index) => {
                  return (_openBlock(), _createBlock("div", {
                    key: `dropdown-product-item-${index}`,
                    class: [
                  'products-item',
                  'd-flex',
                  item.classes,
                  _ctx.$route.path.includes(item.link) && 'products-item--current'
                ],
                    onClick: ($event: any) => (_ctx.$emit('onDropdownProductsItemClick', index))
                  }, [
                    _createVNode("div", {
                      class: "products-item__image-outer",
                      style: `background-color: ${item.color}`
                    }, [
                      _createVNode("img", {
                        class: "products-item__image",
                        src: require(`@/assets/images/products/${item.image}`),
                        alt: `Image ${item.title}`
                      }, null, 8, ["src", "alt"])
                    ], 4),
                    _createVNode("div", _hoisted_5, [
                      _createVNode("div", _hoisted_6, _toDisplayString(item.title), 1),
                      _createVNode("div", _hoisted_7, _toDisplayString(item.desc), 1)
                    ]),
                    (
                    _ctx.$route.path.includes(item.link) && _ctx.pageRelatedLinks.length
                  )
                      ? (_openBlock(), _createBlock("ul", {
                          key: 0,
                          class: "header-nav__list",
                          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                        }, [
                          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.pageRelatedLinks, (relatedLink, relatedLinkIndex) => {
                            return (_openBlock(), _createBlock("li", {
                              key: `header-menu-nav-item-${relatedLinkIndex}`,
                              class: [
                        'header-nav__list-item',
                        relatedLink.items.length &&
                          'header-nav__list-item--dropdown',
                        relatedLink.isOpen && 'header-nav__list-item--active'
                      ]
                            }, [
                              (relatedLink.items.length)
                                ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                                    _createVNode("span", {
                                      class: "header-nav__link menu-item-link",
                                      onClick: ($event: any) => (relatedLink.isOpen = !relatedLink.isOpen)
                                    }, _toDisplayString(relatedLink.title), 9, ["onClick"]),
                                    (relatedLink.isOpen)
                                      ? (_openBlock(), _createBlock("ul", _hoisted_8, [
                                          (_openBlock(true), _createBlock(_Fragment, null, _renderList(relatedLink.items, (relatedLinkItem, relatedLinkItemIndex) => {
                                            return (_openBlock(), _createBlock("li", {
                                              key: 
                              `header-menu-related-item-${relatedLinkItemIndex}`
                            ,
                                              class: "header-nav__list-item"
                                            }, [
                                              _createVNode("a", {
                                                class: "header-nav__link",
                                                href: relatedLinkItem.url
                                              }, _toDisplayString(relatedLinkItem.title), 9, ["href"])
                                            ]))
                                          }), 128))
                                        ]))
                                      : _createCommentVNode("", true)
                                  ], 64))
                                : (_openBlock(), _createBlock("a", {
                                    key: 1,
                                    class: "header-nav__link",
                                    href: relatedLink.url
                                  }, _toDisplayString(relatedLink.title), 9, ["href"]))
                            ], 2))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ], 10, ["onClick"]))
                }), 128))
              : _createCommentVNode("", true)
          ], 2),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.mainNavLinks, (mainNav, mainNavIndex) => {
            return (_openBlock(), _createBlock("li", {
              key: `header-menu-nav-item-${mainNavIndex}`,
              class: [
              'header-nav__list-item',
              mainNav.items.length && 'header-nav__list-item--dropdown',
              mainNav.isOpen && 'header-nav__list-item--active'
            ]
            }, [
              (mainNav.items.length)
                ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                    _createVNode("span", {
                      class: "header-nav__link menu-item-link",
                      onClick: ($event: any) => (mainNav.isOpen = !mainNav.isOpen)
                    }, _toDisplayString(mainNav.title), 9, ["onClick"]),
                    (mainNav.isOpen)
                      ? (_openBlock(), _createBlock("ul", _hoisted_9, [
                          (_openBlock(true), _createBlock(_Fragment, null, _renderList(mainNav.items, (mainNavItem, mainNavItemIndex) => {
                            return (_openBlock(), _createBlock("li", {
                              key: `header-menu-related-item-${mainNavItemIndex}`,
                              class: "header-nav__list-item"
                            }, [
                              _createVNode("a", {
                                class: "header-nav__link",
                                href: mainNavItem.url,
                                target: 
                      mainNavItem.url === 'https://support.gifted.co/en/'
                        ? '_blank'
                        : '_self'
                    
                              }, _toDisplayString(mainNavItem.title), 9, ["href", "target"])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                : (_openBlock(), _createBlock("a", {
                    key: 1,
                    class: "header-nav__link",
                    href: mainNav.url,
                    target: 
                mainNav.url === 'https://support.gifted.co/en/'
                  ? '_blank'
                  : '_self'
              
                  }, _toDisplayString(mainNav.title), 9, ["href", "target"]))
            ], 2))
          }), 128))
        ])
      ])
    ]),
    _createVNode("div", _hoisted_10, [
      _createVNode("div", _hoisted_11, [
        _createVNode("a", {
          href: `${_ctx.businessPrefix}login#/sign-up`,
          class: "btn btn--secondary",
          onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clickSignUp && _ctx.clickSignUp(...args)), ["stop","prevent"]))
        }, _toDisplayString(_ctx.$t("text.signUp")), 9, ["href"]),
        _createVNode("a", {
          href: `${_ctx.businessPrefix}login`,
          class: "btn btn--primary",
          onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clickLogIn && _ctx.clickLogIn(...args)), ["stop","prevent"]))
        }, _toDisplayString(_ctx.$t("text.logIn")), 9, ["href"])
      ])
    ])
  ]))
}