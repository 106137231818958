import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "main-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")
  const _component_router_view = _resolveComponent("router-view")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: ['main-content', _ctx.subMenu.length && 'main-content--submenu']
    }, [
      (_ctx.isPageHeaderTopMenuLoaded && _ctx.isPageHeaderSubMenuLoaded)
        ? (_openBlock(), _createBlock(_component_Header, {
            key: 0,
            "top-menu-items": _ctx.topMenu,
            "sub-menu-items": _ctx.subMenu
          }, null, 8, ["top-menu-items", "sub-menu-items"]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view),
      _createVNode(_component_Footer)
    ], 2)
  ]))
}