import PaginatedItem from "@/types/PaginatedItem";

const stateObj: {
  itemsList: PaginatedItem[];
} = {
  itemsList: []
};

export type PaginationState = typeof stateObj;

export default {
  namespaced: true,
  state: () => stateObj,
  mutations: {
    setItemsList(state: PaginationState, itemsList: []) {
      state.itemsList = itemsList;
    },
    removeItem(state: PaginationState, itemId: any) {
      state.itemsList = state.itemsList.filter(item => item.id !== itemId);
    }
  },
  getters: {
    getItemsList(state: PaginationState) {
      return state.itemsList;
    }
  }
};
