import { mapGetters } from "vuex";
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    ...mapGetters({
      businessPrefix: "options/getBusinessPrefix",
      responsiveState: "getResponsiveState",
    })
  },
  methods: {
    setResponsiveState() {
      let responsiveState = "desktop";
      if (window.innerWidth <= 767) {
        responsiveState = "mobile";
      } else if (window.innerWidth < 1024) {
        responsiveState = "tablet";
      } else if (window.innerWidth < 1200) {
        responsiveState = "desktop-sm";
      } else {
        responsiveState = "desktop";
      }
      this.$store.dispatch("setResponsiveState", responsiveState);
    },
  },
  mounted() {
    this.setResponsiveState();
    window.addEventListener("resize", this.setResponsiveState);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setResponsiveState);
  }
});
