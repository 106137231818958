
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterNav",
  data() {
    return {
      footerNav: [
        {
          title: this.$t("text.whoWeAre"),
          collapsed: true,
          items: [
            {
              name: this.$t("text.blog"),
              url: "blog"
            },
            {
              name: this.$t("text.pricing"),
              url: "hr/pricing"
            },
            {
              name: this.$t("text.startGiftingItsFree"),
              url: `${this.businessPrefix}login`
            }
          ]
        },
        {
          title: this.$t("text.whatWeDo"),
          collapsed: true,
          items: [
            {
              name: this.$t("text.employeeRecognition"),
              url: `${this.businessPrefix}products/hr`
            },
            {
              name: this.$t("text.managerRecognitionProgram"),
              url: "hr/users"
            },
            {
              name: this.$t("text.automaticEmployeeRecognition"),
              url: "hr/birthdays"
            },
            {
              name: this.$t("text.customerRewardsGifting"),
              url: `${this.businessPrefix}products/client`
            },
            {
              name: this.$t("text.brandedSwag"),
              url: `${this.businessPrefix}products/swag`
            },
            {
              name: this.$t("text.employeeGiftingBudgetManagement"),
              url: "hr/reports"
            },
            {
              name: this.$t("text.workFromHomeGifting"),
              url: "hr/WFH"
            }
          ]
        },
        {
          title: this.$t("text.howCanWeHelp"),
          collapsed: true,
          items: [
            {
              name: this.$t("text.support"),
              url: "https://support.gifted.co/en/",
              targetBlank: true
            },
            {
              name: this.$t("text.integrations"),
              url: "hr/integrations"
            }
          ]
        }
      ]
    };
  },
  methods: {
    onColTitleClick(index: number) {
      // @ts-ignore
      if (this.responsiveState !== "mobile") return;

      this.footerNav[index].collapsed = !this.footerNav[index].collapsed;
    }
  }
});
