import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import LayoutBusinessSite from "@/layouts/LayoutBusinessSite.vue";
import LayoutProductPage from "@/layouts/LayoutProductPage.vue";
import LayoutContentPages from "@/layouts/LayoutContentPages.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "BusinessSite",
        component: LayoutBusinessSite,
        children: [
            {
                path: "",
                name: "FrontPage",
                component: () =>
                    import(/* webpackChunkName: "FrontPage" */ "../views/ProductPage.vue")
            }
        ]
    },
    {
        path: "/products",
        name: "BusinessSiteProduct",
        component: LayoutProductPage,
        children: [
            {
                path: ":slug",
                name: "ProductPage",
                component: () =>
                    import(/* webpackChunkName: "ProductPage" */ "../views/ProductPage.vue")
            }
        ]
    },
    {
        path: "/page",
        name: "BusinessSitePage",
        component: LayoutContentPages,
        children: [
            {
                path: ":slug",
                name: "ContentPage",
                component: () =>
                    import(/* webpackChunkName: "ContentPage" */ "../views/ContentPage.vue")
            }
        ]
    },
    {
        path: "/allstars",
        name: "BusinessSiteTestimonials",
        component: LayoutBusinessSite,
        children: [
            {
                path: "",
                name: "Testimonials",
                component: () =>
                    import(/* webpackChunkName: "Testimonials" */ "../views/TestimonialsPage.vue")
            },
            {
                path: ":id",
                name: "Testimonial",
                component: () =>
                    import(/* webpackChunkName: "Testimonial" */ "../views/TestimonialPage.vue")
            }
        ]
    },
    {
        path: '/404',
        name: 'NotFoundPage',
        component: () =>
            import(/* webpackChunkName: "NotFoundPage" */ "../views/NotFoundPage.vue")
    },
    { path: '/:pathMatch(.*)*', redirect: '/404' },

];

const router = createRouter({
    // history: createWebHistory(process.env.VUE_APP_BASE_URL),
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {top: 0};
        }
    }
});

export default router;
