
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {mapGetters} from "vuex";
import {bool} from "yup";

export default defineComponent({
  name: "LayoutBusinessSite",
  components: { Header, Footer },
  data() {
    return {
      isPageHeaderTopMenuLoaded: false,
      isPageHeaderSubMenuLoaded: false,
      isPageWidgetsLoaded: false,
      widgets: []
    };
  },
  computed: {
    ...mapGetters({
      topMenu: "header/getTopMenu",
      subMenu: "header/getSubMenu"
    }),
    isLoaded(): boolean {
      return this.isPageHeaderTopMenuLoaded && this.isPageHeaderSubMenuLoaded && this.isPageWidgetsLoaded;
    }
  },
  watch: {
    "$route.params.slug": {
      handler: function() {
          this.isPageWidgetsLoaded = false;
          this.isPageHeaderTopMenuLoaded = false;
          this.isPageHeaderSubMenuLoaded = false;

          this.$store.dispatch("header/getHeaderMenu").then(() => {
            this.isPageHeaderTopMenuLoaded = true;
          });

          this.$store
              .dispatch(
                  "header/getHeaderSubMenu",
                  this.$router.currentRoute.value.fullPath
              )
              .then(() => {
                this.isPageHeaderSubMenuLoaded = true;
              });

          this.$store
              .dispatch(
                  "productPage/getPageData",
                  this.$router.currentRoute.value.fullPath
              )
              .then((response: { data: { widgets: [] } }) => {
                this.widgets = response.data.widgets;
                this.isPageWidgetsLoaded = true;
              })
              .catch(() => {
                this.$router.push({ name: "FrontPage" });
              });
      },
      deep: true,
      immediate: true
    }
  }
});
