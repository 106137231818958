const Helpers = {
  //set the token to local storage
  setToken: function setToken(session: {
    token: string;
    name: string;
    expiresAt: string;
    manager: any;
  }) {
    localStorage.setItem("ember_simple_auth:session", JSON.stringify(session));
  },

  //read and return token from local storage
  getToken: function getToken() {
    const storedSession = localStorage.getItem("ember_simple_auth:session");
    if (localStorage.getItem("ember_simple_auth:session") && storedSession) {
      return JSON.parse(storedSession).token;
    }

    return false;
  },

  //read users session from local storage
  getSession: function getSession() {
    const storedSession = localStorage.getItem("ember_simple_auth:session");
    if (localStorage.getItem("ember_simple_auth:session") && storedSession) {
      return JSON.parse(storedSession);
    }

    return false;
  },

  getSystemPath: function() {
    return localStorage.getItem("hrSystemName");
  },

  getManager: function() {
    const storedSession = localStorage.getItem("ember_simple_auth:session");
    if (localStorage.getItem("ember_simple_auth:session") && storedSession) {
      return JSON.parse(storedSession).manager;
    }
  },

  getAllowedActions: function() {
    const storedSession = localStorage.getItem("ember_simple_auth:session");
    if (localStorage.getItem("ember_simple_auth:session") && storedSession) {
      return JSON.parse(storedSession).allowedActions;
    }
  }
};

export default Helpers;
