import { defineComponent } from "vue";

export default defineComponent({
    methods: {
        gtmTrackAndSave(authType: string, placeValue: string) {
            if (this.$gtm && this.$gtm.enabled()) {
                window.dataLayer?.push({
                    event: 'gifted',
                    name: authType + '_click',
                    [authType + '_place_click']: placeValue
                });
            }

            const gtmData = JSON.parse(localStorage.getItem('gtmData') ?? '{}');
            gtmData.authType = authType;
            gtmData.clickPlace = placeValue;
            localStorage.setItem('gtmData', JSON.stringify(gtmData));
        }
    }
});
