import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "footer-nav d-flex" }
const _hoisted_2 = {
  key: 0,
  class: "footer-nav__col-items"
}
const _hoisted_3 = { class: "footer-nav__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.footerNav, (col, index) => {
      return (_openBlock(), _createBlock("div", {
        key: `footer-nav-col-${index}`,
        class: "footer-nav__col"
      }, [
        _createVNode("div", {
          class: ['footer-nav__col-title', col.collapsed && 'is-collapsed'],
          onClick: ($event: any) => (_ctx.onColTitleClick(index))
        }, _toDisplayString(col.title), 11, ["onClick"]),
        (
          (_ctx.responsiveState === 'mobile' && !col.collapsed) ||
            _ctx.responsiveState !== 'mobile'
        )
          ? (_openBlock(), _createBlock("div", _hoisted_2, [
              _createVNode("ul", _hoisted_3, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(col.items, (navItem, navItemIndex) => {
                  return (_openBlock(), _createBlock("li", {
                    key: `footer-nav-list-item-${index}-${navItemIndex}`,
                    class: "footer-nav__list-item"
                  }, [
                    _createVNode("a", {
                      href: navItem.url,
                      target: navItem.targetBlank ? '_blank' : '_self'
                    }, _toDisplayString(navItem.name), 9, ["href", "target"])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}