const he = {
  text: {
    extra: "Extra",
    error: "Error",
    close: "Close",
    checkout: "Checkout",
    reviewAndSendGifts: "Review and send gifts",
    redeemYourGifts: "Redeem your gifts",
    openGift: "Open gift",
    sendGifts: "Send gifts!",
    continueShopping: "Continue shopping",
    total: "Total",
    balance: "Balance",
    attention: "Attention",
    lookingGood: "Looking good!",
    modalAttentionHeading:
      "This action is final and can’t be changed. Never ever!",
    modalAttentionText:
      "Your gift cards will be sent to your email you can redeem them from there",
    budgetFiltersText: "Select your gifts and amout from the list below",
    checkoutError: "Oops! Something went wrong when trying to produce your gift card. Please reach out to customer support to get this fixed in a jiffy.",
    category: "Category",
    sortBy: "Sort by",
    searchGifts: "Search gifts",
    filter: "Filter",
    addToCart: "Add to cart",
    amount: "Amount",
    relatedGifts: "Related gifts",
    redemptionHistory: "Redemption history",
    youAlreadyUsedThisGiftCardAt: "You already used this gift card at",
    resend: "Resend",
    resendGift: "Resend gift",
    send: "Send",
    giftCardTo: "gift card to",
    email: "Email",
    phone: "Phone",
    back: "Back",
    privacyPolicy: "I understand that my order is non returnable and is subject to the",
    termsAndConditions: "Terms and Conditions",
    and: "and",
    privacyNotice: "Privacy Notice",
    theGiftWasResentSucccessfully: "The gift was resent successfully",
    greatThanks: "Great, thanks!",
    sendAThankYou: "Send a thank you",
    expDate: "Exp. date",
    lastGiftForThisCard: "Last gift for this card!",
    thankYouMessageHeader: "Thank you message",
    pickGreetingMessage: "Pick a greeting message or write your own",
    senderName: "Your Name",
    processing: "Processing ..."
  },
  error: {
    fieldIsRequired: "field is required",
    suppliersAreRequired: "please select suppliers",
    supplierIsRequired: "please select the supplier",
    notThanked: "Gratitude not sent"
  }
};

export default he;
