import { mapGetters } from "vuex";
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    ...mapGetters({
      footerData: "footer/getFooterData"
    })
  }
});
