
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "LayoutContentPages",
  components: { Header, Footer },
  data() {
    return {
      isPageHeaderTopMenuLoaded: false,
      isPageHeaderSubMenuLoaded: true,
      prevRoute: null
    };
  },
  computed: {
    ...mapGetters({
      topMenu: "header/getTopMenu",
      subMenu: "header/getSubMenu"
    })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // @ts-ignore
      vm.prevRoute = from;
    });
  },
  mounted() {
    this.isPageHeaderTopMenuLoaded = false;

    this.$store.dispatch("header/getHeaderMenu").then(() => {
      this.isPageHeaderTopMenuLoaded = true;
    });

    // @ts-ignore
    if (this.prevRoute && this.prevRoute.name !== undefined) {
      this.isPageHeaderSubMenuLoaded = false;

      // @ts-ignore
      // prettier-ignore
      this.$store.dispatch("header/getHeaderSubMenu", this.prevRoute.fullPath).then(() => {
        this.isPageHeaderSubMenuLoaded = true;
      });
    }
  }
});
