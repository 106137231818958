import { createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "footer__top" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "footer__top-button-outer text-center" }
const _hoisted_5 = { class: "footer__nav" }
const _hoisted_6 = { class: "container d-flex" }
const _hoisted_7 = { class: "footer-about" }
const _hoisted_8 = { class: "footer__bottom" }
const _hoisted_9 = { class: "container d-flex" }
const _hoisted_10 = { class: "footer__terms" }
const _hoisted_11 = { class: "footer__terms-items d-flex" }
const _hoisted_12 = { class: "footer__terms-item" }
const _hoisted_13 = { class: "footer__terms-item" }
const _hoisted_14 = { class: "footer__terms-item" }
const _hoisted_15 = { class: "footer__socials" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FooterNav = _resolveComponent("FooterNav")
  const _component_Socials = _resolveComponent("Socials")

  return (_openBlock(), _createBlock("footer", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", {
          class: "footer__top-title text-center",
          innerHTML: _ctx.$t('text.footerSectionTopTitle')
        }, null, 8, ["innerHTML"]),
        _createVNode("div", _hoisted_4, [
          _createVNode("a", {
            class: "footer__top-button btn btn--primary",
            href: `${_ctx.businessPrefix}login#/sign-up`,
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clickSignUp && _ctx.clickSignUp(...args)), ["stop","prevent"]))
          }, _toDisplayString(_ctx.$t("text.startGifting")), 9, ["href"])
        ])
      ])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("div", _hoisted_6, [
        _createVNode("div", _hoisted_7, [
          _createVNode("img", {
            class: "footer-about__logo",
            width: "105",
            height: "49",
            src: require(`@/assets/images/gifted-logo.svg`),
            alt: "Gifted logo"
          }, null, 8, ["src"]),
          _createVNode("div", {
            class: "footer-about__text",
            innerHTML: _ctx.$t('text.footerAboutText')
          }, null, 8, ["innerHTML"])
        ]),
        _createVNode(_component_FooterNav)
      ])
    ]),
    _createVNode("div", _hoisted_8, [
      _createVNode("div", _hoisted_9, [
        _createVNode("div", _hoisted_10, [
          _createVNode("div", _hoisted_11, [
            _createVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("text.copyrights", { year: _ctx.currentYear })), 1),
            _createVNode("div", _hoisted_13, [
              _createVNode("a", {
                href: _ctx.footerData.termsLink
              }, _toDisplayString(_ctx.$t("text.termsConditions")), 9, ["href"])
            ]),
            _createVNode("div", _hoisted_14, [
              _createVNode("a", {
                href: _ctx.footerData.privacyLink
              }, _toDisplayString(_ctx.$t("text.privacyPolicy")), 9, ["href"])
            ])
          ])
        ]),
        _createVNode("div", _hoisted_15, [
          _createVNode(_component_Socials)
        ])
      ])
    ])
  ]))
}