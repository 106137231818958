export default {
  namespaced: true,
  state: {
    testimonialsList: [],
  },
  getters: {
    testimonialsList(state: any) {
      return state.testimonialsList;
    },
    testimonialBySlug: (state: any) => (slug: any) => {
      return state.testimonialsList.find((item:any) => item.slug === slug);
    }
  },
  actions: {
    getTestimonialsList(context: any) {
      return new Promise((resolve, reject) => {
        fetch('/getTestimData').then(res => {
          return res.json()
        }).then(data => {
          context.commit('setTestimonialsList', data);
          resolve(data);
        })
          .catch(err => reject(err))
      })
    }
  },
  mutations: {
    setTestimonialsList(state: any, data: any) {
      state.testimonialsList = data.testimonials;
    }
  }
}
