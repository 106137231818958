
import { defineComponent } from "vue";

export default defineComponent({
  name: "HeaderMenu",
  props: {
    mainNavLinks: {
      type: Array,
      default: []
    },
    dropdownProductsItems: {
      type: Array,
      default: []
    },
    pageRelatedLinks: {
      type: Array,
      default: []
    }
  },
  emits: ["onDropdownProductsItemClick"],
  data() {
    return {
      isProductsOpen: false
    };
  },
  mounted() {
    this.isProductsOpen = this.$route.path.includes("products/");
  },
  methods: {
    clickSignUp() {
      this.gtmTrackAndSave('signup');
      //@ts-ignore
      window.location.href = `${this.businessPrefix}login#/sign-up`;
    },
    clickLogIn() {
      this.gtmTrackAndSave('login');
      //@ts-ignore
      window.location.href = `${this.businessPrefix}login`;
    },
    gtmTrackAndSave(authType: string) {
      const placeValue = 'Top right';

      if (this.$gtm && this.$gtm.enabled()) {
        window.dataLayer?.push({
          event: 'gifted',
          name: authType + '_click',
          [authType + '_place_click']: placeValue
        });
      }

      const gtmData = JSON.parse(localStorage.getItem('gtmData') ?? '{}');
      gtmData.authType = authType;
      gtmData.clickPlace = placeValue;
      localStorage.setItem('gtmData', JSON.stringify(gtmData));
    }
  },
  beforeUnmount() {
    document.body.classList.remove("no-scroll");
    // @ts-ignore
    this.$parent.isHeaderMenuOpen = false;
  }
});
