const stateObj: {
  businessPrefix: string;
} = {
  businessPrefix: ""
};

export type OptionsState = typeof stateObj;

export default {
  namespaced: true,
  state: () => stateObj,
  mutations: {
    setBusinessPrefix(state: OptionsState, businessPrefix: string) {
      state.businessPrefix = businessPrefix;
    }
  },
  getters: {
    getBusinessPrefix(state: OptionsState) {
      return state.businessPrefix;
    }
  }
};
