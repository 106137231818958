
import { defineComponent } from "vue";
import Socials from "@/components/Socials.vue";
import FooterNav from "@/components/FooterNav.vue";
import footer from "@/mixins/footer";

export default defineComponent({
  name: "Footer",
  mixins: [footer],
  components: { Socials, FooterNav },
  computed: {
    currentYear() {
      const year = new Date().getFullYear();
      return year;
    }
  },
  mounted() {
    if (!this.footerData.length) this.$store.dispatch("footer/getFooterData");
  },
  methods: {
    clickSignUp() {
      const signupPlaceClick = 'Footer';

      if (this.$gtm && this.$gtm.enabled()) {
        window.dataLayer?.push({
          'event': 'gifted',
          'name': 'signup_click',
          'signup_place_click': signupPlaceClick,
        });
      }

      const gtmData = JSON.parse(localStorage.getItem('gtmData') ?? '{}');
      gtmData.authType = 'signup';
      gtmData.clickPlace = signupPlaceClick;
      localStorage.setItem('gtmData', JSON.stringify(gtmData));

      //@ts-ignore
      window.location.href = `${this.businessPrefix}login#/sign-up`;
    },
  }
});
