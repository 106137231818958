
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "LayoutProductPage",
  components: { Header, Footer },
  data() {
    return {
      isPageHeaderTopMenuLoaded: false,
      isPageHeaderSubMenuLoaded: false,
      isPageWidgetsLoaded: false,
      widgets: []
    };
  },
  computed: {
    ...mapGetters({
      topMenu: "header/getTopMenu",
      subMenu: "header/getSubMenu"
    })
  },
  watch: {
    "$route.params.slug": {
      handler: function(slug) {
        if (this.$route.name === "ProductPage" && slug !== undefined) {
          this.isPageWidgetsLoaded = false;
          this.isPageHeaderTopMenuLoaded = false;
          this.isPageHeaderSubMenuLoaded = false;

          this.$store.dispatch("header/getHeaderMenu").then(() => {
            this.isPageHeaderTopMenuLoaded = true;
          });

          this.$store
            .dispatch(
              "header/getHeaderSubMenu",
              this.$router.currentRoute.value.fullPath
            )
            .then(() => {
              this.isPageHeaderSubMenuLoaded = true;
            });

          this.$store
            .dispatch(
              "productPage/getPageData",
              this.$router.currentRoute.value.fullPath
            )
            .then((response: { data: { widgets: [] } }) => {
              this.widgets = response.data.widgets;
              this.isPageWidgetsLoaded = true;
            })
            .catch(() => {
              this.$router.push({ name: "FrontPage" });
            });
        }
      },
      deep: true,
      immediate: true
    }
  }
});
