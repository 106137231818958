const en = {
  app: {
    mainTitle: "Gifted - Your Company's Saas Based Recognition, Retention, and Rewards Platform for your Employees, Clients and Business Partners",
    mainDescription: "Consolidate all of your company's employee and client gifting needs onto one easy-to-use yet comprehensive dashboard.  Deliver a personalized employee recognition and client gifting experience to drive engagement & retention, and to bolster relationships and loyalty."
  },
  text: {
    goToDashboard: 'Go to Dashboard',
    signUp: "Sign up",
    logIn: "Log in",
    sectionTopTitle: "Show Some Appreciation",
    sectionTopSubtitle:
      "Send the perfect gift for any occasion using Gifted’s wide selection of <b>gift cards</b> and custom <b>branded swag</b>.",
    startGifting: "Start Gifting!",
    itIsFree: "It’s free!",
    customersHeading:
      "Join over 3000 Corporate Clients who are gifting with GifteD",
    footerSectionTopTitle:
      "Create Your <span class='highlighted'>Appreciation</span> Program Today",
    copyrights: "© {year} Gifted",
    userAgreements: "User Agreements",
    privacy: "Privacy",
    termsConditions: "Terms & Conditions",
    privacyPolicy: "Privacy Policy",
    ourCatalogue: "Our catalogue",
    footerAboutText:
      "Easily and efficiently engage with your employees, clients, and partners. <br /> Completely free. <br /> Dust off your cape, and be the company hero.",
    menu: "Menu",
    aboutUs: "About us",
    resources: "Resources",
    hrPlatformsTitle: "We play well with...",
    hrPlatformsText:
      "Gifted simplifies your team management with integration across popular HR Platforms such as",
    allIntegrations: "All integrations",
    giftingPackagesTitle: "Gifting package",
    videosTitle: "Video",
    videosText: "Watch Gifted in action",
    products: "Products",
    more: "More",
    whoWeAre: "Who We Are",
    whatWeDo: "What We Do",
    howCanWeHelp: "How Can We Help",
    blog: "Blog",
    pricing: "Pricing",
    startGiftingItsFree: "Start Gifting (It’s Free)",
    employeeRecognition: "Employee Recognition",
    managerRecognitionProgram: "Manager Recognition Program",
    automaticEmployeeRecognition: "Automatic Employee Recognition",
    customerRewardsGifting: "Customer Rewards & Gifting",
    brandedSwag: "Branded Swag",
    employeeGiftingBudgetManagement: "Employee Gifting Budget Management",
    workFromHomeGifting: "Work From Home Gifting",
    support: "Support",
    integrations: "Integrations"
  },
  benefits: {
    frontPage: {
      title1:
        "The Gift Card You Would <span class='highlighted'>Want To Get</span>",
      desc1:
        "Take the guessing out of gifting and allow your lucky recipient to choose from a variety of diverse options.",
      title2: "Gifts Are Meant <span class='highlighted'>To Be Shared</span>",
      desc2:
        "Share the Wealth or Treat Yourself: Divide your reward between any number of available brands.",
      title3: "Gift Anywhere: <span class='highlighted'>Send a Gift to</span>",
      desc3:
        "Gift clients, employees, or anyone anywhere with a worldwide catalog of popular brands.",
      title4: "Gifting With A <span class='highlighted'>Personal Touch</span>",
      desc4:
        "Give a unique gift experience by personalizing each message and uploading your own logo.",
      title5:
        "<span class='highlighted'>Tracking</span> and <span class='highlighted'>Transparency</span>",
      desc5:
        "Easily navigate the platform’s streamlined dashboard to find the right information at the right time."
    }
  },
  products: {
    hr: {
      title: "Gifted <span class='highlighted'>HR</span>",
      subtitle: "Recognition Is A <span class='highlighted'>Team Sport</span>"
    },
    client: {
      title: "Gifted <span class='highlighted'>Client</span>",
      subtitle:
        "<span class='highlighted'>Outreach</span> and <span class='highlighted'>Brand</span> Recognition"
    },
    swag: {
      title: "Gifted <span class='highlighted'>Swag</span>",
      subtitle: "Swag Up Your"
    }
  },
  error: {
    fieldIsRequired: "field is required",
    suppliersAreRequired: "please select suppliers",
    supplierIsRequired: "please select the supplier",
    giftInCart: "This gift is already in the cart",
    amountCantBeNull: "Amount can't be null",
    balanceLow: "You don't have enough money on you balance to add this gift",
    minValue: "Amount can't be less then Min value for this supplier",
    maxValue: "Amount can't be higher then Max value for this supplier",
    onlyInt: "Amount must be an integer",
    savingEmailError: "Something went wrong",
    notThanked: "Gratitude not sent",
    allFieldsAreRequired: "All fields are required",
    messageMaxChars: "Message can contain maximum 200 chars"
  }
};

export default en;
