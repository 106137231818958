
import { defineComponent } from "vue";
import helpers from "@/helpers";

export default defineComponent({
  name: "App",
  data() {
    return {
      manager: {},
      systemPrefix: ""
    };
  },
  mounted() {
    document.body.classList.add("ltr", "en");
    document.title = this.$t("app.mainTitle");
    this.manager = helpers.getManager();
    this.systemPrefix = `/system/${helpers.getSystemPath()}`;

    const meta = document.createElement('meta');
    meta.name = "description";
    meta.content = this.$t("app.mainDescription");
    document.getElementsByTagName('head')[0].appendChild(meta);

    // this.$store.dispatch("budget/getBudgetData");

    // Use axios example
    // this.axios.get("/test", {}).then(response => {
    //   console.log(response.data);
    // });
  },
  unmounted() {
    document.body.classList.remove("ltr", "en");
  }
});
