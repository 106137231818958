import { createApp } from "vue";
import VueGtm from 'vue-gtm';
import moment from "moment";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mitt from "mitt";
import options from "@/mixins/options";

import axios from "axios";
import VueAxios from "vue-axios";

const emitter = mitt();

const appParams = document.querySelector("#appParams") as HTMLElement | null;
const prefix = appParams ? appParams.dataset.prefix as string : "/";
const gtmId = appParams ? appParams.dataset.gtm_id as string : '';

store.commit("options/setBusinessPrefix", prefix);

const axiosConfig: { baseURL: string; headers?: any } = {
  baseURL: prefix
};
export const HTTP = axios.create(axiosConfig);

// Internationalization
import { createI18n } from "vue-i18n";
import enLocale from "./locales/en";
import heLocale from "./locales/he";
const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en: enLocale,
    he: heLocale
  }
});

// Moment
moment.locale("en");

// vue-scrollto
import VueScrollTo from "vue-scrollto";

// Vue click away
import VueClickAway from "vue3-click-away";

// vue lazyload
import VueLazyloadNext from "vue-lazyload-next";

const app = createApp(App)
  .mixin(options)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueScrollTo)
  .use(VueClickAway)
  .use(VueLazyloadNext, { attempt: 1, listenEvents: ["scroll"] })
  .use(VueAxios, HTTP);

if (gtmId) {
    app.use(
        VueGtm, {
            id: gtmId,
            vueRouter: router,
        }
    );
}

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$moment = moment;
app.mount("#app");
