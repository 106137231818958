import { createStore } from "vuex";
import moduleLoader from "./loader";
import moduleNotifications from "./notifications";
import modulePagination from "./pagination";
import moduleHeader from "./header";
import moduleOptions from "./options";
import moduleProductPage from "./productPage";
import moduleFooter from "./footer";
import testimonialsList from "./testimonialsList";
import createPersistedState from "vuex-persistedstate";
const dataState = createPersistedState({
  paths: ["options.businessPrefix"]
});

export default createStore({
  state: {
    responsiveState: "desktop"
  },
  getters: {
    getResponsiveState(state) {
      return state.responsiveState;
    }
  },
  mutations: {
    setResponsiveState(state, responsiveState) {
      state.responsiveState = responsiveState;
    }
  },
  actions: {
    setResponsiveState(vuexContext, responsiveState) {
      vuexContext.commit("setResponsiveState", responsiveState);
    }
  },
  modules: {
    loader: moduleLoader,
    notifications: moduleNotifications,
    pagination: modulePagination,
    header: moduleHeader,
    options: moduleOptions,
    productPage: moduleProductPage,
    footer: moduleFooter,
    testimonialsList
  },
  plugins: [dataState]
});
