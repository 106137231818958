import { HTTP } from "@/main";
import MenuItem from "@/types/MenuItem";

const stateObj: {
  topMenu: MenuItem[];
  subMenu: MenuItem[];
} = {
  topMenu: [],
  subMenu: []
};

export type HeaderState = typeof stateObj;

export default {
  namespaced: true,
  state: () => stateObj,
  mutations: {
    setTopMenu(state: HeaderState, topMenu: MenuItem[]) {
      state.topMenu = topMenu;
    },
    setSubMenu(state: HeaderState, subMenu: MenuItem[]) {
      state.subMenu = subMenu;
    }
  },
  getters: {
    getTopMenu(state: HeaderState) {
      return state.topMenu;
    },
    getSubMenu(state: HeaderState) {
      return state.subMenu;
    },
    getPageMenu(state: HeaderState) {
      return {
        topMenu: state.topMenu,
        subMenu: state.subMenu
      };
    }
  },
  actions: {
    getHeaderMenu({ commit }: any) {
      return new Promise((resolve, reject) => {
        HTTP.get("/businessHeader")
          .then(response => {
            commit("setTopMenu", response.data.topMenu);
            resolve(response);
          })
          .catch(error => {
            console.log("getHeaderMenuError", error);
            reject(error);
          });
      });
    },
    getHeaderSubMenu({ commit }: any, slug: string) {
      return new Promise((resolve, reject) => {
        HTTP.post("/businessHeaderSubMenu", { slug })
          .then(response => {
            commit("setSubMenu", response.data.subMenu);
            resolve(response);
          })
          .catch(error => {
            console.log("getHeaderSubMenuError", error);
            reject(error);
          });
      });
    }
  }
};
