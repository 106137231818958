import { HTTP } from "@/main";

const stateObj: {
  pageWidgets: [];
} = {
  pageWidgets: []
};

export type ProductPageState = typeof stateObj;

export default {
  namespaced: true,
  state: () => stateObj,
  actions: {
    // eslint-disable-next-line
    getPageData({ state }: any, slug: string) {
      return new Promise((resolve, reject) => {
        return HTTP.post("/productPageData", { slug })
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
