import { HTTP } from "@/main";
import FooterData from "@/types/FooterData";

const stateObj: {
  footerData: FooterData;
} = {
  footerData: {
    isPrivacyLink: false,
    isTermsLink: false,
    privacyLink: "",
    termsLink: ""
  }
};

export type FooterState = typeof stateObj;

export default {
  namespaced: true,
  state: () => stateObj,
  mutations: {
    setFooterData(state: FooterState, footerDate: FooterData) {
      state.footerData = footerDate;
    }
  },
  getters: {
    getFooterData(state: FooterState) {
      return state.footerData;
    }
  },
  actions: {
    getFooterData({ commit }: any) {
      return new Promise((resolve, reject) => {
        HTTP.get("footerData")
          .then(response => {
            commit("setFooterData", response.data);
            resolve(response);
          })
          .catch(error => {
            console.log("getFooterData", error);
            reject(error);
          });
      });
    }
  }
};
