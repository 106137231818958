import { createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  ref: "headerMain",
  class: "header__inner header__inner--main d-flex"
}
const _hoisted_2 = { class: "container d-flex" }
const _hoisted_3 = { class: "header__logo-outer" }
const _hoisted_4 = { href: "https://gifted.co" }
const _hoisted_5 = { class: "header-nav" }
const _hoisted_6 = { class: "header-nav__list d-flex" }
const _hoisted_7 = {
  key: 0,
  class: "header-nav__list-item header-nav__list-item--dropdown"
}
const _hoisted_8 = { class: "header-nav__link" }
const _hoisted_9 = { class: "header-nav__list custom-scrollbar" }
const _hoisted_10 = { class: "header-nav__link" }
const _hoisted_11 = { class: "header-nav__list" }
const _hoisted_12 = { class: "header-nav__link" }
const _hoisted_13 = { class: "header-nav__list custom-scrollbar" }
const _hoisted_14 = { class: "header__buttons" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = {
  href: '/gifting-platform#/dashboard',
  class: "btn btn--primary"
}
const _hoisted_18 = {
  key: 1,
  class: "menu-button-outer"
}
const _hoisted_19 = { class: "container" }
const _hoisted_20 = { class: "dropdown-menu d-flex custom-scrollbar" }
const _hoisted_21 = { class: "products-item__title" }
const _hoisted_22 = { class: "products-item__desc" }
const _hoisted_23 = {
  key: 1,
  class: "header__inner header__inner--product"
}
const _hoisted_24 = {
  ref: "headerProductContainer",
  class: "container d-flex"
}
const _hoisted_25 = {
  key: 0,
  ref: "logoOuterSecondary",
  class: "header__logo-outer"
}
const _hoisted_26 = { class: "header-nav header-nav--secondary" }
const _hoisted_27 = {
  ref: "navListSecondary",
  class: "header-nav__list d-flex"
}
const _hoisted_28 = { class: "header-nav__link" }
const _hoisted_29 = { class: "header-nav__list header-nav__list--related custom-scrollbar" }
const _hoisted_30 = {
  key: 0,
  class: "header-nav__list-item header-nav__list-item--dropdown"
}
const _hoisted_31 = { class: "header-nav__link" }
const _hoisted_32 = {
  id: "nav-secondary-more-list",
  class: "header-nav__list header-nav__list--related custom-scrollbar"
}
const _hoisted_33 = { class: "header-nav__link" }
const _hoisted_34 = { class: "header-nav__list header-nav__list--related custom-scrollbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderMenu = _resolveComponent("HeaderMenu")

  return (_openBlock(), _createBlock("header", {
    ref: "header",
    class: [
      'header',
      _ctx.isDropdownProductsOpen && 'header--shadow',
      _ctx.isHeaderSticky && 'header--sticky'
    ]
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("a", _hoisted_4, [
            _createVNode("img", {
              class: "header__logo",
              height: "27",
              src: require('@/assets/images/gifted-logo.svg'),
              alt: "Gifted"
            }, null, 8, ["src"])
          ])
        ]),
        (_ctx.responsiveState !== 'mobile')
          ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
              _createVNode("nav", _hoisted_5, [
                _createVNode("ul", _hoisted_6, [
                  _createVNode("li", {
                    ref: "productsMenuItem",
                    class: [
                  'header-nav__list-item',
                  'header-nav__list-item--dropdown',
                  _ctx.isDropdownProductsOpen && 'header-nav__list-item--mouseover',
                  _ctx.isHoverableArea && 'is-hoverable-area'
                ],
                    onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDropdownProductsMouseOver && _ctx.onDropdownProductsMouseOver(...args))),
                    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onDropdownProductsMouseLeave && _ctx.onDropdownProductsMouseLeave(...args)))
                  }, [
                    _createVNode("span", {
                      ref: "productsMenuItemLink",
                      class: "header-nav__link"
                    }, _toDisplayString(_ctx.$t("text.products")), 513)
                  ], 34),
                  (_ctx.responsiveState === 'tablet' && _ctx.mainNavLinks.length)
                    ? (_openBlock(), _createBlock("li", _hoisted_7, [
                        _createVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("text.more")), 1),
                        _createVNode("ul", _hoisted_9, [
                          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.mainNavLinks, (mainNavLink, index) => {
                            return (_openBlock(), _createBlock("li", {
                              key: `main-nav-item-${index}`,
                              class: [
                      'header-nav__list-item',
                      mainNavLink.items.length &&
                        'header-nav__list-item--dropdown'
                    ]
                            }, [
                              (mainNavLink.items.length)
                                ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                                    _createVNode("span", _hoisted_10, _toDisplayString(mainNavLink.title), 1),
                                    _createVNode("ul", _hoisted_11, [
                                      (_openBlock(true), _createBlock(_Fragment, null, _renderList(mainNavLink.items, (mainNavLinkItem, mainNavLinkIndex) => {
                                        return (_openBlock(), _createBlock("li", {
                                          key: `main-nav-item-${mainNavLinkIndex}`,
                                          class: "header-nav__list-item"
                                        }, [
                                          _createVNode("a", {
                                            class: "header-nav__link",
                                            href: mainNavLinkItem.url,
                                            target: 
                              mainNavLinkItem.url ===
                              'https://support.gifted.co/en/'
                                ? '_blank'
                                : '_self'
                            
                                          }, _toDisplayString(mainNavLinkItem.title), 9, ["href", "target"])
                                        ]))
                                      }), 128))
                                    ])
                                  ], 64))
                                : (_openBlock(), _createBlock("a", {
                                    key: 1,
                                    class: "header-nav__link",
                                    href: mainNavLink.url,
                                    target: 
                        mainNavLink.url === 'https://support.gifted.co/en/'
                          ? '_blank'
                          : '_self'
                      
                                  }, _toDisplayString(mainNavLink.title), 9, ["href", "target"]))
                            ], 2))
                          }), 128))
                        ])
                      ]))
                    : (
                  _ctx.responsiveState !== 'mobile' && _ctx.responsiveState !== 'tablet'
                )
                      ? (_openBlock(true), _createBlock(_Fragment, { key: 1 }, _renderList(_ctx.mainNavLinks, (mainNavLink, mainNavLinkIndex) => {
                          return (_openBlock(), _createBlock("li", {
                            key: `main-nav-item-${mainNavLinkIndex}`,
                            class: [
                    'header-nav__list-item',
                    mainNavLink.items.length &&
                      'header-nav__list-item--dropdown'
                  ]
                          }, [
                            (mainNavLink.items.length)
                              ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                                  _createVNode("span", _hoisted_12, _toDisplayString(mainNavLink.title), 1),
                                  _createVNode("ul", _hoisted_13, [
                                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(mainNavLink.items, (mainNavLinkItem, mainNavLinkIndex) => {
                                      return (_openBlock(), _createBlock("li", {
                                        key: `main-nav-item-${mainNavLinkIndex}`,
                                        class: "header-nav__list-item"
                                      }, [
                                        _createVNode("a", {
                                          class: "header-nav__link",
                                          href: mainNavLinkItem.url,
                                          target: 
                            mainNavLinkItem.url ===
                            'https://support.gifted.co/en/'
                              ? '_blank'
                              : '_self'
                          
                                        }, _toDisplayString(mainNavLinkItem.title), 9, ["href", "target"])
                                      ]))
                                    }), 128))
                                  ])
                                ], 64))
                              : (_openBlock(), _createBlock("a", {
                                  key: 1,
                                  class: "header-nav__link",
                                  href: mainNavLink.url,
                                  target: 
                      mainNavLink.url === 'https://support.gifted.co/en/'
                        ? '_blank'
                        : '_self'
                    
                                }, _toDisplayString(mainNavLink.title), 9, ["href", "target"]))
                          ], 2))
                        }), 128))
                      : _createCommentVNode("", true)
                ])
              ]),
              _createVNode("div", _hoisted_14, [
                (!_ctx.authenticated)
                  ? (_openBlock(), _createBlock("div", _hoisted_15, [
                      _createVNode("a", {
                        href: `${_ctx.businessPrefix}login#/sign-up`,
                        class: "btn btn--secondary",
                        onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clickSignUp && _ctx.clickSignUp(...args)), ["stop","prevent"]))
                      }, _toDisplayString(_ctx.$t("text.signUp")), 9, ["href"]),
                      _createVNode("a", {
                        href: `${_ctx.businessPrefix}login`,
                        class: "btn btn--primary",
                        onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clickLogIn && _ctx.clickLogIn(...args)), ["stop","prevent"]))
                      }, _toDisplayString(_ctx.$t("text.logIn")), 9, ["href"])
                    ]))
                  : (_openBlock(), _createBlock("div", _hoisted_16, [
                      _createVNode("a", _hoisted_17, _toDisplayString(_ctx.$t("text.goToDashboard")), 1)
                    ]))
              ])
            ], 64))
          : (_openBlock(), _createBlock("div", _hoisted_18, [
              _createVNode("button", {
                class: ['menu-button', _ctx.isHeaderMenuOpen && 'menu-button--active'],
                type: "button",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onMenuButtonClick && _ctx.onMenuButtonClick(...args)))
              }, null, 2)
            ]))
      ])
    ], 512),
    (_ctx.responsiveState !== 'mobile' && _ctx.isDropdownProductsOpen)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          ref: "productsDropdownMenu",
          class: "header__inner header__inner--dropdown-menu",
          onMouseleave: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onDropdownProductsMouseLeave && _ctx.onDropdownProductsMouseLeave(...args)))
        }, [
          _createVNode("div", _hoisted_19, [
            _createVNode("div", _hoisted_20, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.dropdownProductsItems, (item, index) => {
                return (_openBlock(), _createBlock("div", {
                  key: `dropdown-product-item-${index}`,
                  class: [
              'dropdown-menu__item',
              'products-item',
              item.classes,
              _ctx.hoverableItemIndex !== null && 'is-hoverable',
              _ctx.hoverableItemIndex !== null &&
                _ctx.hoverableItemIndex === index &&
                'is-hoverable-active'
            ],
                  onClick: ($event: any) => (_ctx.onDropdownProductsItemClick(index)),
                  onMouseover: ($event: any) => (_ctx.hoverableItemIndex = index),
                  onMouseleave: _cache[6] || (_cache[6] = ($event: any) => (_ctx.hoverableItemIndex = null))
                }, [
                  _createVNode("div", {
                    class: "products-item__image-outer",
                    style: `background-color: ${item.color}`
                  }, [
                    _createVNode("img", {
                      class: "products-item__image",
                      src: require(`@/assets/images/products/${item.image}`),
                      alt: `Image ${item.title}`
                    }, null, 8, ["src", "alt"])
                  ], 4),
                  _createVNode("div", _hoisted_21, _toDisplayString(item.title), 1),
                  _createVNode("div", _hoisted_22, _toDisplayString(item.desc), 1)
                ], 42, ["onClick", "onMouseover"]))
              }), 128))
            ])
          ])
        ], 544))
      : _createCommentVNode("", true),
    (_ctx.pageRelatedLinks.length && _ctx.responsiveState !== 'mobile')
      ? (_openBlock(), _createBlock("div", _hoisted_23, [
          _createVNode("div", _hoisted_24, [
            (_ctx.productMenuLogo)
              ? (_openBlock(), _createBlock("div", _hoisted_25, [
                  _createVNode("span", {
                    class: "product-menu-logo",
                    innerHTML: _ctx.productMenuLogo
                  }, null, 8, ["innerHTML"])
                ], 512))
              : _createCommentVNode("", true),
            _createVNode("div", _hoisted_26, [
              _createVNode("ul", _hoisted_27, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.pageRelatedLinks, (relatedLink, relatedLinkIndex) => {
                  return (_openBlock(), _createBlock(_Fragment, {
                    key: `related-link-${relatedLinkIndex}`
                  }, [
                    (
                  relatedLinkIndex < _ctx.pageRelatedLinks.length - _ctx.itemsMoveIndex
                )
                      ? (_openBlock(), _createBlock("li", {
                          key: 0,
                          ref: `relatedLink${relatedLinkIndex}`,
                          class: [
                  'header-nav__list-item',
                  relatedLink.items.length && 'header-nav__list-item--dropdown'
                ]
                        }, [
                          (relatedLink.items.length)
                            ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                                _createVNode("span", _hoisted_28, _toDisplayString(relatedLink.title), 1),
                                _createVNode("ul", _hoisted_29, [
                                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(relatedLink.items, (relatedLinkItem, relatedLinkItemIndex) => {
                                    return (_openBlock(), _createBlock("li", {
                                      key: 
                        `related-link-item-${relatedLinkIndex}-${relatedLinkItemIndex}`
                      ,
                                      class: "header-nav__list-item"
                                    }, [
                                      _createVNode("a", {
                                        class: "header-nav__link",
                                        href: relatedLinkItem.url
                                      }, _toDisplayString(relatedLinkItem.title), 9, ["href"])
                                    ]))
                                  }), 128))
                                ])
                              ], 64))
                            : (_openBlock(), _createBlock("a", {
                                key: 1,
                                class: "header-nav__link",
                                href: relatedLink.url
                              }, _toDisplayString(relatedLink.title), 9, ["href"]))
                        ], 2))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128)),
                (_ctx.isSubmenuMoreItem)
                  ? (_openBlock(), _createBlock("li", _hoisted_30, [
                      _createVNode("span", _hoisted_31, _toDisplayString(_ctx.$t("text.more")), 1),
                      _createVNode("ul", _hoisted_32, [
                        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.pageRelatedLinks, (moreRelatedLink, moreRelatedLinkIndex) => {
                          return (_openBlock(), _createBlock(_Fragment, {
                            key: `more-related-link-${moreRelatedLinkIndex}`
                          }, [
                            (
                      moreRelatedLinkIndex >=
                        _ctx.pageRelatedLinks.length - _ctx.itemsMoveIndex
                    )
                              ? (_openBlock(), _createBlock("li", {
                                  key: 0,
                                  class: [
                      'header-nav__list-item',
                      moreRelatedLink.items.length &&
                        'header-nav__list-item--dropdown'
                    ]
                                }, [
                                  (moreRelatedLink.items.length)
                                    ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                                        _createVNode("span", _hoisted_33, _toDisplayString(moreRelatedLink.title), 1),
                                        _createVNode("ul", _hoisted_34, [
                                          (_openBlock(true), _createBlock(_Fragment, null, _renderList(moreRelatedLink.items, (moreRelatedLinkItem, moreRelatedLinkItemIndex) => {
                                            return (_openBlock(), _createBlock("li", {
                                              key: 
                            `more-related-link-item-${moreRelatedLinkIndex}-${moreRelatedLinkItemIndex}`
                          ,
                                              class: "header-nav__list-item"
                                            }, [
                                              _createVNode("a", {
                                                class: "header-nav__link",
                                                href: moreRelatedLinkItem.url
                                              }, _toDisplayString(moreRelatedLinkItem.title), 9, ["href"])
                                            ]))
                                          }), 128))
                                        ])
                                      ], 64))
                                    : (_openBlock(), _createBlock("a", {
                                        key: 1,
                                        class: "header-nav__link",
                                        href: moreRelatedLink.url
                                      }, _toDisplayString(moreRelatedLink.title), 9, ["href"]))
                                ], 2))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 512)
            ])
          ], 512)
        ]))
      : _createCommentVNode("", true),
    (_ctx.responsiveState === 'mobile' && _ctx.isHeaderMenuOpen)
      ? (_openBlock(), _createBlock(_component_HeaderMenu, {
          key: 2,
          mainNavLinks: _ctx.mainNavLinks,
          dropdownProductsItems: _ctx.dropdownProductsItems,
          pageRelatedLinks: _ctx.pageRelatedLinks,
          onOnDropdownProductsItemClick: _ctx.onDropdownProductsItemClick
        }, null, 8, ["mainNavLinks", "dropdownProductsItems", "pageRelatedLinks", "onOnDropdownProductsItemClick"]))
      : _createCommentVNode("", true)
  ], 2))
}