const stateObj = {
  errorMessage: "",
  successMessage: "",
  notificationIsEnabled: false
};

export type NotificationsState = typeof stateObj;

export default {
  namespaced: true,
  state: () => stateObj,
  mutations: {
    setErrorMessage: (state: NotificationsState, errorMessage: string) => {
      state.successMessage = "";
      state.errorMessage = errorMessage;
    },
    unsetErrorMessage: (state: NotificationsState) => {
      state.errorMessage = "";
    },
    setSuccessMessage: (state: NotificationsState, successMessage: string) => {
      state.errorMessage = "";
      state.successMessage = successMessage;
    },
    unsetSuccessMessage: (state: NotificationsState) => {
      state.successMessage = "";
    },
    setNotificationStatus(state: NotificationsState, status: boolean) {
      state.notificationIsEnabled = status;
    }
  },
  getters: {
    getErrorMessage: (state: NotificationsState) => {
      return state.errorMessage;
    },
    getSuccessMessage: (state: NotificationsState) => {
      return state.successMessage;
    },
    getNotificationStatus: (state: NotificationsState) => {
      return state.notificationIsEnabled;
    }
  },
  actions: {}
};
