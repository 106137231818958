const stateObj = {
  loading: false,
  preloading: false
};

export type LoaderState = typeof stateObj;

export default {
  namespaced: true,
  state: () => stateObj,
  mutations: {
    setLoadingStatus: (state: LoaderState, status: boolean) => {
      state.loading = status;
    },
    setPreloadingStatus: (state: LoaderState, status: boolean) => {
      state.preloading = status;
    }
  },
  getters: {
    getLoadingStatus: (state: LoaderState) => {
      return state.loading;
    },
    getPreloadingStatus: (state: LoaderState) => {
      return state.preloading;
    }
  },
  actions: {}
};
